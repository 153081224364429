.shiye{
  margin: 0 auto;
  width: 1200px;
  margin-top: 50px;

  .title {
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titles {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .titleBottom {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 70px;
    background: #fff;
  }

  .b1 {
    width: 24px;
    height: 5px;
    background: #DA4F27;
    margin-right: 3px;
  }

  .b2 {
    width: 24px;
    height: 5px;
    background: #254F75;
  }
  .shiyeBox {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      overflow: hidden;
      display: inline-block;
    }
    img{
      transition: all 1s ease;
    }
    img:hover{
      transform: scale(1.2);
      opacity: .7;
    }
  }
}

.ShiyeMobile{
  .title {
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titles {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .titleBottom {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 70px;
    background: #fff;
  }

  .b1 {
    width: 24px;
    height: 5px;
    background: #DA4F27;
    margin-right: 3px;
  }

  .b2 {
    width: 24px;
    height: 5px;
    background: #254F75;
  }

  .shiyeBox {
    margin-top: 10px;
    padding: 12px;
    text-align: center;
    .text{
      font-size: 15px;
      color: #254f72;
      font-weight: bold;
    }

    img{
      width: 100%;
      margin-top: 12px;
    }
  }
}
