.hezuo{
    width: 100vw;
    margin:  88px auto 160px auto;
    position: relative;
}
.title{
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;

    .titles {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .titleBottom {
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        width: 70px;
        background: #fff;
    
        .b1 {
          width: 24px;
          height: 5px;
          background: #DA4F27;
          margin-right: 3px;
        }
    
        .b2 {
          width: 24px;
          height: 5px;
          background: #254F75;
        }
      }
}

.text{
    text-align: center;
    font-size: 15px;
    color: rgba(37, 79, 117, 0.59);
    margin-top: 20px;
}
.imgs{
    width: 1200px;
    margin: 50px auto 0 auto;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 10px;
    grid-template-rows: 88px 88px;
    img{
        width: 100%;
        height: 100%;
    }
}
.icon{
    position: absolute;
    right: 0;
    top: 40px;
    transform:translateX(50%);
}
