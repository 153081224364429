@import '~antd/dist/antd.css';
* {
    margin: 0;
    padding: 0;
    user-select: none;
    box-sizing: content-box;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {
    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }
    100%,
    0% {
        transform: scale(1.0);
    }
}

.fenge {
    margin-top: 1px;
    width: 100%;
    height: 24vw;
    background: url("../assets/fenge.png") no-repeat;
    background-size: 100vw auto;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    .title {
        font-size: 35px;
        font-weight: bold;
    }
    .text {
        width: 1200px;
        font-size: 20px;
        line-height: 2;
    }
    .fenge-box {
        display: flex;
        width: 1200px;
        align-items: center;
        justify-content: space-around;
    }
}

#root {
    width: 100vw;
}

img {
    -webkit-user-drag: none;
}

@font-face {
    font-family: bahnschrift;
    src: url('./bahnschrift.ttf');
}

@media screen and (min-width: 1200px) {
    /*banner1*/
    .swiper-pagination.banner1-bullets {
        bottom: 20px;
        left: 0;
        width: 100%;
    }
    .banner1 {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        margin: 0 5px;
    }
    .banner1-active {
        width: 42px;
        height: 8px;
        background: #D14729;
        border-radius: 4px;
    }
    /*banner2*/
    .swiper-pagination.banner2-bullets {
        bottom: 20px;
        left: auto;
        width: auto;
        right: 30px;
    }
    .banner2 {
        width: 23px;
        height: 6px;
        background: #EAEAEA;
        display: inline-block;
        margin: 0 5px;
    }
    .banner2-active {
        background: #083F8F;
    }
}

.custom-class{
    margin-top: 20vh;
}
