.container {
  padding: 10px;
  background-color: #f5f5f5;
  .item {
    padding: 20px;
    cursor: pointer;
    background-color: #fff;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .title {
      font-size: 18px;
      line-height: 30px;
      color: #222;
      font-weight: 550;
    }
    .time {
      font-size: 12px;
      color: #888;
      line-height: 40px;
    }
    .content {
      font-size: 15px;
      color: #666;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
