.footer {
    width: 100vw;
    height: 344px;
    background: #083f8f;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
}

// .footer::after {
//     position: absolute;
//     bottom: 80px;
//     display: block;
//     content: '';
//     width: 100vw;
//     height: 1px;
//     background: rgba(255, 255, 255, 0.2);
// }

.footerleft {
    width: 239px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftitem {
    cursor: pointer;
}

.right {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.main {
    height: 282px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.main:nth-child(1) {
    height: 282px;
}

.main:nth-child(2) {
    width: 100%;
    height: 62px;
    justify-content: center;
    border-top: 2px solid hsla(0,0%,100%,.2);
}

.main a {
    color: #fff;
    font-size: 14px;
}

@media screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1200px) {
    .main {
        width: 80%;
        padding: 0 5%;
    }
}

.mainHx {
    width: 600px;
}

@media screen and (min-width: 575px) {
    .titie {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 23px;
        font-weight: bold;
    }
}
@media screen and (max-width: 575px) {
    .titie {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
    }
}

.ml {
    margin-left: 10px;
    color: rgba(51, 201, 255, 0.86);
    display: flex;
    align-items: center;
}

.ml:before {
    display: block;
    content: '·';
    font-size: 30px;
    margin-right: 10px;
}

.jianjie {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.img {
    margin-right: 30px;
}

.phone {
    align-items: center;
    margin-top: 30px;
}

.phone,
.address {
    display: flex;
    font-size: 15px;
}

@media screen and (min-width: 575px) {
    .address {
        font-size: 15px;
    }
}
@media screen and (max-width: 575px) {
    .address {
        font-size: 12px;
    }
}

.text {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.41);
    margin-right: 10px;
}

.text2 {
    color: rgba(255, 255, 255, 0.86);
}

.mb {
    margin-bottom: 18px;
}


@media screen and (min-width: 575px) {
    .phoneNum {
        font-size: 22px;
    }
}
@media screen and (max-width: 575px) {
    .phoneNum {
        font-size: 18px;
    }
}


.kuozan {
    height: 180px;
    margin-left: 10vw;
    width: 310px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}


.title2 {
    font-size: 16px;
    color: #30B6E9;
    margin-bottom: 30px;
}

.listItem {
    cursor: pointer;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.76);
    margin-bottom: 12px;
}


.huanxin {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 575px) {
    .huanxin {
        flex-direction: column;
        font-size: 12px;
    }
}

.mr {
    margin-right: 10px;
}
