.qiyejianjie {
  width: 1200px;
  margin: 0 auto;
  .title {
    margin-top: 50px;
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titles {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .titleBottom {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      width: 70px;
      background: #fff;

      .b1 {
        width: 24px;
        height: 5px;
        background: #DA4F27;
        margin-right: 3px;
      }

      .b2 {
        width: 24px;
        height: 5px;
        background: #254F75;
      }
    }

  }

  .main {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .video {
      width: 576px;
      height: 324px;
      background: rgb(0, 0, 0);
    }

    .texts {
      width: 600px;
    }

    .texts p {
      font-size: 16px;
    }

    .texts p span{
      font-weight: bold;
    }
  }
}
