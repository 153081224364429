.container {
  .item {
    padding-left: 20px;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    &:not(:first-child) {
      border-top: 1px solid #e5e5e5;
    }
    span {
      margin-left: 20px;
      color: #333;
      &:nth-of-type(2) {
        color: #ccc;
      }
    }
    img {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .active {
    height: 80px;
    line-height: 80px;
    span {
      &:nth-of-type(1) {
        font-size: 18px;
        font-weight: 550;
      }
    }
    img {
      top: 30px;
    }
  }
}
