.header {
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: transparent;
  z-index: 999999999999;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, .9);
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  .header {
    width: 1200px;
    padding-left: calc(45vw - 800px);
    padding-right: calc(55vw - 400px);
  }
}

@media screen and (max-width: 1200px) {
  .header {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.navs {
  margin-right: 200px;
  width: 400px;
  display: flex;
  text-align: center;
  line-height: 78px;
  justify-content: space-between;
  position: relative;
}

.NavSlidePar {
  position: relative;
}

.itemBoxs {
  position: absolute;
  top: 79px;
  right: 0;
  width: 674px;
  height: 300px;
  overflow: hidden;
  display: none;
}

.hoverItem {
  position: absolute;
  right: 0;
  top: 0;
  width: 674px;
  height: 300px;
  background: rgba(255, 255, 255, 0.9);
  display: none;
  padding: 25px 0 25px 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}

.itemBox {
  height: 100%;
}

.itemBoxItem {
  width: 140px;
  text-align: center;
  line-height: 50px;
  height: 50px;
}

.itemBoxItem:hover {
  cursor: pointer;
  background: #fff;
  color: #4a9de6;
}

.NavSlidePar:hover .itemBoxs {
  display: block;
}

.NavSlidePar:hover .hoverItem {
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  display: flex;
}

.item {
  flex: 1;
  font-size: 16px;
  color: #254F75;
  cursor: pointer;
  width: 138px;
}

.item:hover {
  font-weight: bold;
}

.active {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #254F75;
  cursor: pointer;
  position: relative;
  width: 138px;
}

.icons {
  top: 0;
  right: 10%;
  height: 89px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1400px) {
  .icons {
    display: none;
  }
}


.phoneright{
  margin-left: 20px;
  font-weight: bold;
  color: #083f8f;
}

.phoneright .title {
  font-size: 18px;
  margin-bottom: 8px;
}

.phoneright .subtitle {
  width: 126px;
  text-align: justify;
  text-align-last: justify;
  font-size: 15px;
}

.yidong {
  position: absolute;
  bottom: -1px;
  z-index: -1;
  width: 138px;
  height: 3px;
  background: #DA4F27;
  transition: all .3s ease;
}
