.news {
  margin-top: 20px;
  position: relative;

  .title {
    font-size: 16px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titles {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .titleBottom {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      width: 70px;
      background: #fff;

      .b1 {
        width: 24px;
        height: 5px;
        background: #DA4F27;
        margin-right: 3px;
      }

      .b2 {
        width: 24px;
        height: 5px;
        background: #254F75;
      }
    }

  }

  .main {
    width: 100vw;
    padding: 12px;
    background-color: #f5f5f5;
    box-sizing: border-box;

    .swiper {
      width: 100%;

      .swiperItem {
        .swiperImg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          .inner{
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 50%;
            img{
              display: flex;
              position: absolute;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .textBox {
          background: #FFF;
          padding: 15px 20px;
        }
      }
    }

    .newList {
      margin-top: 20px;
      width: 100%;

      .navs {
        width: 100%;
        font-size: 16px;
        color: #254F75;
        display: flex;
        border-bottom: 2px solid rgba(112, 112, 112, 0.16);

        .navItem {
          width: 142px;
          height: 38px;
          background: transparent;
          opacity: 1;
          border-radius: 206px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 16px;
          cursor: pointer;
        }

        .navItemActive {
          width: 142px;
          height: 38px;
          background: #DA4F27;
          opacity: 1;
          border-radius: 206px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 16px;
          color: #fff;
          cursor: pointer;
        }
      }
      .container {
        margin-top: 10px;
        background-color: #f5f5f5;
        .item {
          padding: 12px;
          cursor: pointer;
          background-color: #fff;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .title {
            text-align: left;
            font-size: 18px;
            line-height: 30px;
            color: #222;
            font-weight: 550;
          }
          .time {
            font-size: 12px;
            color: #888;
            line-height: 40px;
          }
          .content {
            font-size: 15px;
            color: #666;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

    }
  }
}

