.container {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  & > img {
    display: block;
    &:nth-of-type(2) {
      cursor: pointer;
    }
  }
}

.item {
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid #e5e5e5;
  }
  span {
    margin-left: 20px;
    color: #333;
    &:nth-of-type(2) {
      color: #ccc;
    }
  }
  img {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
