.navs {
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, 50%);
    width: 100vw;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}

.active {
    font-size: 16px;
    width: 247px;
    height: 88px;
    background: #DA4F27;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.active .text {
    color: #fff;
}

.navItem {
    width: 247px;
    height: 88px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #7595AA;
    font-weight: 200;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    .active999 {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 0;
        background-color: rgba(162, 162, 162, .1);
        transition: all .2s ease;
    }
    &:hover .active999 {
        height: 100%;
    }
}

.head {
    width: 100vw;
    height: 436px;
    position: relative;
    background-repeat: no-repeat;
    position: relative;
}

.leftimg {
    position: absolute;
    right: -135px;
    top: 125%;
}

// about1
.con1 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px;
    box-sizing: border-box;
    img{
        width: 100%;
    }
}

.con2 {
    width: 100%;
    max-width: 1200px;
    padding: 12px;
    box-sizing: border-box;
    height: 397px;
    margin: 103px auto 300px;
    img{
        width: 100%;
    }
}

.con3 {
    padding-top: 40px;
    width: 100vw;
    height: 420px;
    margin: 0 auto;
    text-align: center;
    background: url('../../../assets/about/about3.png') no-repeat;
    img{
        width: 100%;
    }
}

// about1
//about2
.about21 {
    width: 1200px;
    height: 780px;
    margin: 92px auto 140px;
}

.about22 {
    width: 1920px;
    height: 360px;
    margin: 0px auto 0px;
    background: url('../../../assets/about/about21.png');
}

.about23 {
    width: 1200px;
    height: 330px;
    margin: -190px auto 85px;
    text-align: center;
}

.about23img {
    padding-top: 30px;
    background-color: #FFFFFF;
    height: 208px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 4px;
}

.about24 {
    width: 1118px;
    height: 518px;
    margin: 0px auto 120px;
}

.about23Text1 {
    margin-top: 10px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #FFFFFF;
    opacity: 1;
}

.about23Text2 {
    margin-top: 22px;
    margin-bottom: 32px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 1;
}

//about2
//about4
.about4 {
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin: 0 auto;
}

.about4 .con41wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 76px;
    flex-wrap: wrap;
}

.con41 {
    margin-top: 22px;
    width: 380px;
    height: 320px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 4px;
}

.con42wrap {
    margin: 0 auto;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    max-width: 1200px;
    margin-bottom: 20px;
}

.con42h1 {
    margin-bottom: 24px;
}

.con42h1 .p1 {
    width: 120px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #254F75;
    opacity: 1;
}

.con42h1 .p2 {
    width: 336px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    opacity: 1;
}

.inputwrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 80px;
}

.input {
    width: 560px;
    height: 70px;
    line-height: 70px;
    background: #F6F6F6;
    opacity: 1;
    border-radius: 2px;
    outline: none;
    border: none;
    text-indent: 60px;
    margin-bottom: 16px;
}



.img {
    width: 160px;
    height: 70px;
    line-height: 70px;
    background: #F6F6F6;
    opacity: 1;
    border-radius: 2px;
    outline: none;
    border: none;
    text-indent: 60px;
    margin-bottom: 16px;
}

.textarea {
    width: 1200px;
    height: 120px;
    min-width: 1176px;
    line-height: 25px;
    background: #F6F6F6;
    opacity: 1;
    outline: none;
    border: none;
    text-indent: 60px;
    margin-bottom: 16px;
    border-radius: 2px;
}

.input::placeholder,
.textarea::placeholder {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}

.button {
    width: 592px;
    height: 70px;
    line-height: 70px;
    background: #64A8E2;
    outline: none;
    border: none;
    margin-bottom: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
}

#map {
    width: 1920px;
    margin: 0 auto;
    height: 404px;
}

@media screen and (max-width:500px) {
    .head {
        height: 236px;
        background-position: center;
    }
}
