@media screen and (max-width: 900px) {
  .newsList {
    display: none;
  }
}

.banners {
  position: relative;
  overflow: hidden;
}

.box {
  width: 1100px;
  margin: 0 auto;
  padding-top: 7.5vw;
}

.img {
  width: 100vw;
  height: 32vw;
}

.banner {
  display: flex;
  transition: all 1s ease;
}

.bannerItem {
  width: 100vw;
}

.newsList {
  width: 100vw;
  height: 54px;
  z-index: 9;
  background: url(../../../assets/image/boxbg.png);

  .newsListBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.title {
  width: 116px;
  position: relative;
  z-index: 1;
  height: 34px;
  background: url(../../../assets/image/news_information.png);
  color: #296FD2;
  font-weight: bold;
  font-size: 15px;
  line-height: 33px;
  text-align: center;
}

.oh {
  overflow: hidden;
  position: relative;
}

.news {
  width: 800px;
  height: 54px;
  line-height: 54px;
  margin-left: 30px;
  color: #fff;
  overflow: hidden;
}
.video{
  //background: #ddd url('/public/swiper.png');
  background-size: 100vw 32vw;;
}
.newsBox {
  transition: all 1s ease;
}

.BannerList {
  position: relative;

  &:hover .navBoxleft3{
      display: flex;
  }

  .navBoxleft3 {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    //display: none;

    .navBoxleft4 {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      opacity: 0.3;
      background-image: url("../../../assets/image/left.png");

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .navBoxleft5 {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      transform: rotate(180deg);
      opacity: 0.3;
      background-image: url("../../../assets/image/left.png");

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

.navBoxleft {
  display: flex;
  justify-content: space-between;
  width: 50px;
  position: relative;
  z-index: 9999;

  .navBoxleft1 {
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    opacity: 0.3;
    background-image: url("../../../assets/image/left.png");

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .navBoxleft2 {
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    transform: rotate(180deg);
    opacity: 0.3;
    background-image: url("../../../assets/image/left.png");

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
